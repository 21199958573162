import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound.vue')

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/Home.vue')
	},
	{
		path: '/policy',
		name: 'policy',
		component: () => import('@/views/Policy.vue')
	},
	{
		path: '/404',
		name: 'not-found',
		component: NotFound
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'catch-all',
		component: NotFound
	}
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

export default createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	linkActiveClass: '-active',
	linkExactActiveClass: '-exact-active',
	routes: localizedRoutes,
	scrollBehavior(to) {
		return to.hash
			? {el: to.hash, behavior: 'smooth'}
			: { top: 0, behavior: 'smooth' }
	}
})