import './startup.js'

import {createApp} from 'vue'

import '@/styles/app.scss'
import App from './App.vue'
import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'

import langRouter from '@/extensions/langRouter'
import reveal from '@/extensions/reveal'
import parallax from '@/extensions/parallax'
import GlobalComponents from '@/components/global'
import BeetAPI from '@boite-beet/api-client'

import {Vue3Mq} from 'vue3-mq'
import VueGtag from 'vue-gtag'
import mitt from 'mitt'

/* --- EXTENSIONS --- */
const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(langRouter, 'fr')
    .use(reveal)
    .use(parallax)
    .use(GlobalComponents)
    .use(BeetAPI, {store, apiUrl: import.meta.env.VITE_APP_API_URL})
    .use(Vue3Mq, {
        breakpoints: {
            xs: 0,
            sm: 566,
            md: 1024,
            lg: 1360,
            xl: 1440
        }
    })

/* --- EVENT BUS --- */
const emitter = mitt()
app.provide('emitter', emitter)

/* --- GOOGLE ANALYTICS --- */
app.use(VueGtag, {
    bootstrap: true,
    config: {id: import.meta.env.VITE_APP_GTAG_ID},
}, router)


/* --- GOOGLE RECAPTCHA ---
import {VueReCaptcha} from "vue-recaptcha-v3";
app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_APP_RECAPTCHA_KEY })
*/

/* --- MOUNT --- */
app.mount('#app')
