
export default {
    mounted(el, binding) {
        const speed = binding.value?.speed || 0.5
        const threshold = binding.value?.threshold || 0.15 // Percentage of visibility to trigger parallax
        let ratio = binding.value?.ratio || 12
        const bottomOffset = binding.value?.bottomOffset || 0
        const direction = binding.value?.direction || -1
        const backgroundSize = binding.value?.backgroundSize || '125%'
        let ticking = false

        const isMobile = window.matchMedia("(max-width: 840px)").matches

        if(isMobile) ratio = ratio * 3.5

        el.style.transition = 'all 0.5s ease-out'
        el.style.backgroundSize = backgroundSize


        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const rect = el.getBoundingClientRect()
                    if (rect.top < window.innerHeight && rect.bottom > 0) {
                        const offset = window.pageYOffset + bottomOffset
                        let y = ((offset * speed) / ratio) * direction
                        if(y > 100) {
                            y -= 100
                        }
                        el.style.backgroundPositionY = `calc( 25% + ${y}px)`
                    }
                    ticking = false
                })
                ticking = true
            }
        }

        const handleResize = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    handleScroll()
                    ticking = false
                })
                ticking = true
            }
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > threshold) {
                    window.addEventListener('scroll', handleScroll)
                    window.addEventListener('resize', handleResize)
                } else {
                    window.removeEventListener('scroll', handleScroll)
                    window.removeEventListener('resize', handleResize)
                }
            })
        }, {
            threshold: [threshold]
        })

        observer.observe(el)
        el._handleScroll = handleScroll
        el._handleResize = handleResize
        el._observer = observer
    },
    unmounted(el) {
        window.removeEventListener('scroll', el._handleScroll)
        window.removeEventListener('resize', el._handleResize)
        el._observer.disconnect()
    },
}