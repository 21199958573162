export default {
    mounted(el, binding) {
        const speed = binding.value?.speed || 0.04
        const threshold = binding.value?.threshold || 0.15 // Percentage of visibility to trigger parallax
        const direction = binding.value?.direction || 1
        let ratio = binding.value?.ratio || 1
        let ticking = false

        const isMobile = window.matchMedia("(max-width: 768px)").matches

        if(isMobile) ratio = ratio * 3

        el.style.transition = 'transform 0.5s ease-out'



        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const rect = el.getBoundingClientRect()
                    if (rect.top < window.innerHeight && rect.bottom > 0) {
                        const offset = window.pageYOffset
                        el.style.transform = `translateY(${((offset * speed) * direction) / ratio}px)`
                    }
                    ticking = false
                })
                ticking = true
            }
        }

        const handleResize = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    handleScroll()
                    ticking = false
                })
                ticking = true
            }
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > threshold) {
                    window.addEventListener('scroll', handleScroll)
                    window.addEventListener('resize', handleResize)
                } else {
                    window.removeEventListener('scroll', handleScroll)
                    window.removeEventListener('resize', handleResize)
                }
            })
        }, {
            threshold: [threshold]
        })

        observer.observe(el)
        el._handleScroll = handleScroll
        el._handleResize = handleResize
        el._observer = observer
    },
    unmounted(el) {
        window.removeEventListener('scroll', el._handleScroll)
        window.removeEventListener('resize', el._handleResize)
        el._observer.disconnect()
    },
}