<template>
  <a href="https://boitebeet.com" target="_blank">
    <span>{{ `${$t('beet.credits')} `}}</span>
    <em>Beet</em>
  </a>
</template>

<script>
export default {
	name: 'BeetCredits'
}
</script>