const options = {
    threshold: 0.2,
    duration: 300,
    delay: 150,
    once: false,
    class: 'v-reveal-slide-top', // Class for invisible state
    activeClass: 'v-reveal-slide-top-active', // Class for invisible state
}

export default {
    install: (Vue) => {
        Vue.directive('reveal', {
            mounted: (el, binding) => {
                const mergedOptions = {...options, ...binding.value}
                let data = {first: false, second: false}
                if (binding.value.class && !binding.value.activeClass) {
                    mergedOptions.activeClass = `${mergedOptions.class}-active`
                }

                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        const visible = entry.isIntersecting
                        if (mergedOptions.once && visible) {
                            setTimeout(() => {
                                entry.target.classList.add(mergedOptions.activeClass)
                                entry.target.classList.remove(mergedOptions.class)
                                observer.unobserve(el)
                            }, mergedOptions.delay)
                        } else {
                            if (!data.second) {
                                if (data.first) {
                                    data.second = true
                                }
                                if (visible) {
                                    setTimeout(() => {
                                        entry.target.classList.remove(mergedOptions.activeClass)

                                        entry.target.classList.add(mergedOptions.activeClass)
                                    }, mergedOptions.delay)
                                }
                                if (!data.first) data.first = true
                            }
                        }

                        if (mergedOptions.once && data.second) {
                            entry.target.classList.add(mergedOptions.activeClass)
                            observer.unobserve(el)
                        }
                    })
                }, {threshold: mergedOptions.threshold})
                el.classList.add(mergedOptions.class)
                observer.observe(el)
            }
        })
    }
}

