<template>
  <transition name="slide-up" appear>
    <div v-if="isVisible && !settingsOpen" class="gdpr-bar">
      <section class="gdpr-bar__section">
        <div class="gdpr-bar__title"> {{$t('gdpr.banner.title')}} </div>
        <p> {{$t('gdpr.banner.description')}} </p>
      </section>

      <section class="gdpr-bar__section">
        <button class="gdpr-button" @click="acceptAll"> {{ $t('gdpr.banner.accept') }} </button>
        <button class="gdpr-button" @click="settingsOpen = true"> {{ $t('gdpr.banner.settings') }} </button>
      </section>
    </div>
  </transition>

  <transition name="slide-right"  appear>
    <aside v-if="settingsOpen" class="gdpr-settings" @click="settingsOpen = false">
      <div class="gdpr-settings__panel" @click.stop>
        <section class="gdpr-settings__section">
          <div class="gdpr-settings__section-top">
            <div class="gdpr-bar__title">
              {{ $t('gdpr.settings.title') }}
            </div>
          </div>

          <p> {{ $t('gdpr.settings.description') }} </p>
        </section>

        <section class="gdpr-settings__section">
          <div class="gdpr-settings__section-top">
            <div class="gdpr-bar__title">
              {{ $t('gdpr.settings.essentials.title') }}
            </div>
          </div>

          <p> {{ $t('gdpr.settings.essentials.description') }} </p>
        </section>

        <section class="gdpr-settings__section">
          <div class="gdpr-settings__section-top">
            <div class="gdpr-bar__title">
              {{ $t('gdpr.settings.analytics.title') }}
            </div>

            <div class="gdpr-switch">
              <input id="gtag-switch" v-model="gtag" name="gtag" type="checkbox" class="gdpr-switch__field"/>
              <label for="gtag-switch" class="gdpr-switch__track">
                <span class="gdpr-switch__label">On</span>
                <span class="gdpr-switch__label">Off</span>
              </label>
            </div>
          </div>

          <p> {{ $t('gdpr.settings.analytics.description') }} </p>
        </section>
        <section class="gdpr-settings__section" v-if="hasPixel">
          <div class="gdpr-settings__section-top">
            <div class="gdpr-bar__title">
              Meta facebook pixel
            </div>

            <div class="gdpr-switch">
              <input id="pixel-switch" v-model="pixel" name="gtag" type="checkbox" class="gdpr-switch__field"/>
              <label for="pixel-switch" class="gdpr-switch__track">
                <span class="gdpr-switch__label">On</span>
                <span class="gdpr-switch__label">Off</span>
              </label>
            </div>
          </div>

          <p> {{ $t('gdpr.settings.analytics.description') }} </p>
        </section>

        <section class="gdpr-section">
          <button class="gdpr-button" @click="saveSettings"> {{ $t('gdpr.settings.save') }} </button>
        </section>
      </div>
    </aside>
  </transition>
</template>

<script>
import './style.scss'
import { setOptions, bootstrap } from 'vue-gtag'
export default {
	name: 'Gdpr',
	props: {
		options: {
			type: Object
		}
	},
	data() {
		return {
			isVisible: false,
			pixel: false,
			gtag: false,
			all: false,
			settingsOpen: false
		}
	},
	computed: {
		hasPixel() {
			return import.meta.env.VITE_APP_PIXEL_ID?.length
		},
		colors() {
			if(this.options) {
				let colors = {}
				if(this.options.backgroundColor) colors.backgroundColor = this.options.backgroundColor
				if(this.options.color) colors.color = this.options.color
				if(!this.options.isSolid) colors.backgroundColorAlpha = `${this.options.backgroundColor}E6`
				else colors.backgroundColorAlpha = `${this.options.backgroundColor || '#ffffff'}`
				if(Object.keys(colors).length) return colors
				return null
			}
			return null
		}
	},

	beforeMount() {
		this.setupColors()
		if(localStorage.getItem('gdpr-consent')) {
			this.isVisible = false
		} else {
			setTimeout(() => {
				this.isVisible = true
			}, 3000)
		}

		if(localStorage.getItem('gdpr-pixel')) {
			this.pixel = true
		}

		if(localStorage.getItem('gdpr-gtag')) {
			this.gtag = true
		}
	},

	mounted() {
		if(this.pixel) this.loadPixel()
		if(this.gtag) this.loadGtag()
		if(!this.gtag) {
			this.gtag = true

		}
	},

	methods: {
		setupColors() {
			if(this.colors) {
				if(this.colors.backgroundColor) {
					document.querySelector(':root').style.setProperty('--gdpr-bg-color', this.colors.backgroundColor)
				}
				if(this.colors.backgroundColorAlpha) {
					document.querySelector(':root').style.setProperty('--gdpr-bg-color-alpha', this.colors.backgroundColorAlpha)
				}
				if(this.colors.color) {
					document.querySelector(':root').style.setProperty('--gdpr-color', this.colors.color)
				}
			}
		},

		saveSettings() {
			if(this.pixel) this.loadPixel()
			else this.disablePixel()

			if(this.gtag) this.loadGtag()
			else this.disableGtag()

			this.settingsOpen = false
			this.isVisible = false

			if(this.pixel || this.gtag) localStorage.setItem('gdpr-consent', '1')
		},
		openSettings() {
			this.settingsOpen = true
		},

		acceptAll() {
			localStorage.setItem('gdpr-consent', '1')
			this.loadPixel()
			this.loadGtag()
			this.isVisible = false
		},

		loadPixel() {
			if(import.meta.env.VITE_APP_PIXEL_ID) {
				if(window.fbq) {
					const fbq = window.fbq
					fbq('consent', 'grant')
					fbq('init', import.meta.env.VITE_APP_PIXEL_ID)
					fbq('track', 'PageView')
					localStorage.setItem('gdpr-pixel', '1')
				}
			}
		},

		loadGtag() {
			if(import.meta.env.VITE_APP_GTAG_ID) {
				bootstrap().then(() => {
					localStorage.setItem('gdpr-gtag', '1')
				}).catch((err) => {
					console.log('error loading gtag', err)
				})
			}
		},
		disableGtag() {
			setOptions({enabled: false})
			localStorage.removeItem('gdpr-gtag')
		},
		disablePixel() {
			if(window.fbq) {
				const fbq = window.fbq
				fbq('consent', 'revoke')
			}
			localStorage.removeItem('gdpr-pixel')
		}
	}
}
</script>
