import { createStore } from 'vuex'

export default createStore({
  state: {
    navigation: ['home', 'about'],
    content: {
      fr: {
        relax: 'DÉTENDEZ-VOUS PENDANT QUE NOUS PRÉPARONS VOTRE EXPÉRIENCE',
        nav: {
          referral: 'PAR RECOMMANDATION PRIVÉE'
        },
        title: {
          line1: 'PROTÉGER LES',
          line2: 'FAMILLES,',
          line3: 'CULTIVER L\'HÉRITAGE'
        },
        subtitle: {
          line1: 'ADAPTÉES À VOTRE HÉRITAGE UNIQUE',
          line2: 'NOS STRATÉGIES D\'ASSURANCE-VIE ENRICHISSENT VOTRE PATRIMOINE',
          line3: 'ET ASSURENT SA PROSPÉRITÉ À TRAVERS LES GÉNÉRATIONS'
        },
        guide: 'ALLONS-Y',
        scroll: 'DÉFILER POUR DÉBUTER L\'AVENTURE',
        section1: {
          title: '<div>Maximisez la puissance de l\'assurance-vie pour garantir vos <i>objectifs à long terme</i></div>',
          description: 'Inspirés par la stratégie de gestion de l\'homme d\'affaires et philanthrope Rockefeller, la planification en cascade, nous créons de la richesse, la faisons fructifier et diversifions vos actifs pour soutenir vos circonstances uniques.',
          items: [
              'LE CHEF DE FAMILLE SOUSCRIT À UNE POLICE D\'ASSURANCE-VIE UNIVERSELLE QUI GÉNÈRE DU CAPITAL',
              'LA POLICE EST TRANSFÉRÉE À UN FIDUCIAIRE DE CONFIANCE OÙ LES AVANTAGES FISCAUX S\'ACCUMULENT ET SE TRANSFORMENT EN FONDS',
              'LE CAPITAL CRÉÉ EST RÉINVESTI DANS DES POLICES D\'ASSURANCE-VIE POUR LES NOUVEAUX MEMBRES DE LA FAMILLE',
              'CETTE CASCADE D\'AVANTAGES PEUT ÊTRE INVESTIE ET FRUCTIFIER INDÉFINIMENT'
          ]
        },
        section2: {
          title: {
            line1: 'NOUS ŒUVRONS À TISSER DES',
            line2: 'LIENS SOLIDES & À',
            line3: 'OFFRIR DES SOLUTIONS \n <i>ÉCLAIRANTES</i>'
          },
          text: 'Guidés par vos meilleurs intérêts, nous naviguons le paysage changeant de la gestion de patrimoine avec des services attentifs, vous offrant un plus grand contrôle sur vos investissements. Notre engagement envers des résultats tangibles, basé sur la confiance, la transparence et l’intégrité, ouvre la voie à des opportunités accrues et à des rendements supérieurs.'
        },
        services: [
          {
            id: 1,
            title: 'Génération & Distribution de capital libre d\'impôts',
            image: '/img/DVFO_Assets_Service_01.svg',
            text: 'IMAGINEZ L\'ASSURANCE-VIE COMME VOTRE ANCRE AU MILIEU DES MERS FINANCIÈRES : UN ACTIF À FAIBLE RISQUE ET EXEMPT D\'IMPÔT QUI DIVERSIFIE VOS INVESTISSEMENTS ET OFFRE UN FILET DE SÉCURITÉ FIABLE DANS VOTRE PARCOURS VERS LA RICHESSE.'
          },
          {
            id: 2,
            title: 'Optimisation de la trésorerie & Croissance des actifs',
            image: '/img/DVFO_Assets_Service_02.svg',
            text: 'GRÂCE À SA VALEUR DE RACHAT, L\'ASSURANCE-VIE OFFRE DES LIQUIDITÉS POUR VOS INVESTISSEMENTS, AINSI QU\'UN POTENTIEL DE CROISSANCE D\'ACTIFS FISCALEMENT AVANTAGEUX, VOUS AIDANT AINSI À FAIRE FRUCTIFIER VOTRE HÉRITAGE FAMILIAL.'
          },
          {
            id: 3,
            title: 'Assurance des personnes-clé',
            image: '/img/DVFO_Assets_Service_03.svg',
            text: 'CONTRIBUE À COUVRIR DES DÉPENSES TELLES QUE LE RECRUTEMENT, LA PERTE DE REVENUS OU LES DETTES EN CAS DE DÉCÈS D\'UN MEMBRE CLÉ DE VOTRE ÉQUIPE.'
          },
          {
            id: 4,
            title: 'Philanthropie stratégique',
            image: '/img/DVFO_Assets_Service_04.svg',
            text: 'UN PARCOURS PHILANTHROPIQUE PLANIFIE VOS DONS AFIN D\'ATTEINDRE UN IMPACT MAXIMAL EN LES ALIGNANT AVEC DES OBJECTIFS ET DES RÉSULTATS SPÉCIFIQUES.'
          },
          {
            id: 5,
            title: 'Planification successorale & Préservation de la richesse',
            image: '/img/DVFO_Assets_Service_05.svg',
            text: 'L\'ASSURANCE-VIE PROTÈGE VOTRE PATRIMOINE EN COUVRANT LES IMPÔTS, LES DETTES ET LES DÉPENSES À VOTRE DÉCÈS, GARANTISSANT QUE VOS ACTIFS PASSENT EN DOUCEUR AUX MAINS DU PROCHAIN LEADER FAMILIAL.'
          },
          {
            id: 6,
            title: 'Évaluation du portefeuille d\'assurance-vie',
            image: '/img/DVFO_Assets_Service_06.svg',
            text: 'NOUS VOUS ORIENTONS VERS LA MEILLEURE SOCIÉTÉ INDÉPENDANTE POUR ÉVALUER VOTRE PORTEFEUILLE ET FOURNIR DES SOLUTIONS LIÉES À L\'ÉVENTAIL DES OPPORTUNITÉS D\'INVESTISSEMENT SUR LE MARCHÉ.'
          }
        ],
        section3: {
          title: 'Connectez avec des gens qui partagent vos valeurs et comprennent <i>vos aspirations</i>',
          description: 'Nos experts, David et Alexandra, s\'occupent personnellement de chacun des clients de la division du bureau familial, garantissant que les stratégies mises en œuvre favorisent la continuité et la stabilité. Leur perspective externe insuffle de nouvelles idées, enrichissant vos stratégies et renforçant votre équipe de conseillers.',
          button: 'EN DÉCOUVRIR PLUS'
        },
        section4: {
          title: {
            line1: 'ENSEMBLE',
            line2: 'ASSURONS UN HÉRITAGE',
            line3: '<i>QUI PERDURE</i>'
          },
          text: 'La sécurité financière et la tranquillité d\'esprit de votre famille sont notre priorité absolue. Laissez-nous vous guider à travers les tendances, anticiper les défis et saisir les opportunités pour assurer votre prospérité.',
          button: 'CONTACTER NOS EXPERTS'
        },
        footnote: '© 2024 <a href="https://dvsf.ca/" target="_blank">DAVID VEILLEUX SERVICES FINANCIERS.</a> TOUS DROITS RÉSERVÉS. | <a href="/policy" target="_blank">POLITIQUE DE CONFIDENTIALITÉ</a>'
      },
      en: {
        relax: 'RELAX WHILE WE PREPARE YOUR EXPERIENCE',
        nav: {
          referral: 'BY PRIVATE REFERRAL'
        },
        title: {
          line1: 'PROTECTING',
          line2: 'FAMILIES,',
          line3: 'NURTURING LEGACIES'
        },
        subtitle: {
          line1: 'TAILORED TO YOUR UNIQUE HERITAGE',
          line2: 'OUR LIFE INSURANCE STRATEGIES ENHANCE YOUR WEALTH',
          line3: 'AND SUSTAIN ITS FLOURISHING FOR GENERATIONS'
        },
        guide: 'LET US GUIDE YOU',
        scroll: 'SCROLL FOR JOURNEY',
        section1: {
          title: '<div>Maximize the power of life insurance benefits to ensure your family\'s <i>long-term goals</i></div>',
          description: 'Inspired by businessman and philanthropist Rockefeller\'s cascade planning management strategy, we create wealth, make it profit and diversify your assets to support your unique circumstances.',
          items: [
            'THE FAMILY\'S LEADER SUBSCRIBES TO A UNIVERSAL LIFE INSURANCE POLICY THAT GENERATES CAPITAL',
            'THE POLICY IS TRANSFERRED TO A TRUSTED FIDUCIARY WHERE THE TAX-FREE BENEFITS ACCUMULATE AND ARE TRANSFORMED INTO FUNDS',
            'THE CAPITAL CREATED IS REINVESTED IN LIFE INSURANCE POLICIES FOR THE NEW MEMBERS OF THE FAMILY',
            'THIS CASCADE OF BENEFITS CAN BE INVESTED AND FRUCTIFY ENDLESSLY'
          ]
        },
        section2: {
          title: {
            line1: 'WE STRIVE TO BUILD STRONG',
            line2: 'RELATIONSHIPS &',
            line3: 'PROVIDE <i>INSIGHTFUL</i> SOLUTIONS'
          },
          text: ' With your best interests as our guiding force, we manage the evolving landscape of wealth management, providing attentive family office services tailored to your needs, empowering you with greater control over investments. Our commitment to tangible results grounded in trust, transparency and integrity open the horizon for greater opportunities and higher returns.'
        },
        services: [
          {
            id:1 ,
            title: 'Generation & Distribution of tax-free capital',
            image: '/img/DVFO_Assets_Service_01.svg',
            text: 'THINK OF LIFE INSURANCE AS YOUR ANCHOR AMIDST THE FINANCIAL SEAS—A TAX-FREE, LOW-RISK ASSET DIVERSIFYING YOUR INVESTMENTS, PROVIDING BENEFITS, AND OFFERING A DEPENDABLE SAFETY NET AS YOU JOURNEY TOWARDS WEALTH.'
          },
          {
            id:2 ,
            title: 'Cash flow optimization & Asset growth',
            image: '/img/DVFO_Assets_Service_02.svg',
            text: 'WITH ITS CASH VALUE FEATURE, LIFE INSURANCE PROVIDES LIQUIDITY FOR EXPENSES OR INVESTMENTS, ALONGSIDE TAX-ADVANTAGED ASSET GROWTH POTENTIAL, HELPING YOU IN GROWING YOUR FAMILY LEGACY.',
          },
          {
            id: 3,
            title: 'Key person insurance',
            image: '/img/DVFO_Assets_Service_03.svg',
            text: 'OFFERS YOU A LIFELINE TO COVER EXPENSES SUCH AS RECRUITING, LOST REVENUE, OR DEBTS IN THE UNFORTUNATE EVENT OF A KEY EXECUTIVE\'S PASSING.'
          },
          {
            id: 4,
            title: 'Strategic philanthropy',
            image: '/img/DVFO_Assets_Service_04.svg',
            text: 'A STRATEGIC GIVING JOURNEY CHARTS YOUR DONATIONS TO ACHIEVE MAXIMUM IMPACT BY ALIGNING THEM WITH SPECIFIC GOALS AND OUTCOMES.'
          },
          {
            id: 5,
            title: 'Estate planning & Wealth preservation',
            image: '/img/DVFO_Assets_Service_05.svg',
            text: 'LIFE INSURANCE PROTECTS YOUR WEALTH BY COVERING TAXES, DEBTS, AND EXPENSES UPON YOUR PASSING, ENSURING YOUR ASSETS MOVE SMOOTHLY INTO THE HANDS OF THE NEXT FAMILY LEADER.'
          },
          {
            id: 6,
            title: 'Life insurance portfolio assessment',
            image: '/img/DVFO_Assets_Service_06.svg',
            text: 'WE STEER YOU TOWARD THE FINEST INDEPENDENT FIRM TO EVALUATE YOUR PORTFOLIO AND FURNISH SOLUTIONS UNRESTRICTED BY THE SCOPE OF INVESTMENT OPPORTUNITIES IN THE MARKET.'
          }
        ],
        section3: {
          title: 'Connect with people \nwho share your values and understand <i>your concerns</i>',
          description: 'Our experts, David and Alexandra, personally attend to each of our family office division clients, ensuring that implemented strategies foster continuity and stability. Their external perspective injects fresh ideas, enriching your strategies and enhancing your team of advisors.',
          button: 'DISCOVER MORE'
        },
        section4: {
          title: {
            line1: 'LET\'S JOURNEY',
            line2: 'TOGETHER TOWARD',
            line3: 'A <i>LASTING LEGACY</i>'
          },
          text: 'Your family\'s financial security and peace of mind are our utmost priority. Allow us to expertly guide you through market trends, anticipate challenges, and capitalize on opportunities to ensure prosperity.',
          button: 'CONTACT OUR EXPERTS'
        },
        footnote: '© 2024 <a href="https://dvsf.ca/en/" target="_blank">DAVID VEILLEUX SERVICES FINANCIERS.</a> ALL RIGHTS RESERVED. | <a href="/en/policy" target="_blank">PRIVACY POLICY</a>'
      }
    }
  },
  getters: {
    navigation: state => state.navigation
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
