<template>
  <div class="navbar">
    <div>Logo</div>
    <div class="navbar__links">
      <span>|</span>
      <button class="navbar__link" @click="$i18n.locale = otherLang.toLowerCase()">{{ otherLang }}</button>
    </div>
  </div>
</template>

<script>
import {LangRouterLink} from '@/components/global'

export default {
	name: 'SiteNav',
	components: {LangRouterLink},
	computed: {
		otherLang() {
			return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
		}
	}
}
</script>
